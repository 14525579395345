<template>
  <div class="my_table" style="border:1px solid #f5f5f5">
    <el-table :data="tableData" style="width: 100%" header-row-class-name="t-header" :row-class-name="mainTableRowClassName" @row-click="mainTableRowClick" @cell-mouse-enter="mainTableCellHover" @cell-mouse-leave="mainTableCellLeave" :show-summary="true" :summary-method="getSummaries">
      <el-table-column type="index" width="100" align="center">
        <template slot-scope="scope">
          <p v-if="hoverRow != scope.$index">{{ scope.$index+1 }}</p>
          <div class="addOrDel" v-else>
            <i class="el-icon-circle-plus-outline" v-if="type != 3" style="color: #409EFF;" @click.stop="addRowClick"></i>
            <i class="el-icon-circle-close" style="color: #F56C6C" @click.stop="delRowClick(scope.$index)"></i>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="code" label="商品编号">
      </el-table-column> -->
      <el-table-column prop="name" label="商品名称" min-width="180">
        <template slot-scope="scope">
          <!-- 自定义商品筛选组件 -->
          <goods-change ref="goodsChange" v-show="mainTableIndex == scope.$index && type !=3" :modelType="modelType" :mainIndex="scope.$index" :nowRowData="nowRowData" :warehouseId="formData.warehouseId" pageType="purchase" @popupClick="getPopupGoods" @goodsSelectEvent="goodsDataEvent"></goods-change>
          <div v-show="mainTableIndex != scope.$index || type == 3" style="padding: 0 10px;">{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="standard" label="规格">
      </el-table-column>
      <el-table-column prop="unit" label="单位">
      </el-table-column>
      <el-table-column prop="distributorId" label="供应商" min-width="100">
        <template slot-scope="scope">
          <el-select v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.distributorId" filterable placeholder="请选择" @change="chengeSupplier($event,scope.row)">
            <el-option v-for="item in supplierList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <div v-else style="padding: 0 10px;">{{ scope.row.distributorName }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="库存">
      </el-table-column>
      <el-table-column prop="purchaseNum" label="数量" min-width="100">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.purchaseNum" type="number" @change="countEvent($event, scope.row,'purchaseNum')"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.purchaseNum }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="purchasePrice" label="单价(元)" min-width="100">
        <template slot-scope="scope">
          <div :ref='"priceInput"+scope.$index' v-if="mainTableIndex == scope.$index && type != 3">
            <el-input @blur="priceInputBlur" @focus="priceInputFocus($event,scope)" :disabled="userInfo.menu_strings[0]!='CAIWU'" v-model="scope.row.purchasePrice" type="number" @change="countEvent($event, scope.row,'price')"></el-input>
          </div>
          <div v-else style="padding: 0 10px;">{{ scope.row.purchasePrice }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="sale" label="销售单价">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index" v-model="scope.row.sale" type="number"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.sale }}</div>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="discountRate" label="折扣率(%)">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.discountRate" type="number" @change="countEvent($event, scope.row,'discountRate')"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.discountRate }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="deduction" label="折扣额(元)">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.deduction" type="number" @change="countEvent($event, scope.row,'deduction')"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.deduction }}</div>
        </template>
      </el-table-column> -->
      <el-table-column prop="totalPrice" label="金额(元)">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.totalPrice" type="number" @change="countEvent($event, scope.row, 'totalPrice')"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.totalPrice }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注">
        <template slot-scope="scope">
          <el-input v-if="mainTableIndex == scope.$index && type != 3" v-model="scope.row.remark" type="text"></el-input>
          <div v-else style="padding: 0 10px;">{{ scope.row.remark }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 优惠 -->
    <div class="youHui">
      <div>
        优惠率(%)：<el-input v-model="preferentialRate" type="number" @change="youhuiCount($event,'preferentialRate')" :disabled="type == 3"></el-input>
      </div>
      <div>
        优惠金额(元)：<el-input v-model="preferentialAmount" type="number" @change="youhuiCount($event,'preferentialAmount')" :disabled="type == 3"></el-input>
      </div>
      <div>
        优惠后金额(元)：<span>{{ discountedAmount }}</span>
      </div>
      <div>
        <el-popover placement="right" width="300" trigger="click" :disabled="popoverDisabled" popper-class="operationRecords">

          <ul>
            <li v-for="(item,index) in operationRecords" :key="index">
              <p>{{item.operationType==0?"制单人":"修改人"}}：{{item.fullName}}</p>
              <p>{{item.operationType==0?"制单日期":"修改日期"}}：{{item.created}}</p>
              <el-divider v-if="operationRecords.length-1!=index"></el-divider>
            </li>
          </ul>

          <span slot="reference" @click="getOperationRecords(1)" class="popoverBtn">操作历史</span>
        </el-popover>
      </div>
    </div>
    <price-change v-if="priceChangeData.show" :priceChangeData='priceChangeData' @priceChangeRow="priceChangeRow"></price-change>
  </div>
</template>
<script>

import goodsChange from "../components/goodsChange.vue";
import priceChange from "../components/priceChange.vue";
import * as math from "mathjs"
export default {
  data() {
    return {
      popoverDisabled: true,
      operationRecords: [],
      userInfo: {},
      priceChangeData: {
        popupTop: "",
        popupLeft: "",
        goodsId: '',
        show: false,
        index: null
      },
      tableData: [
        {}, {}, {}, {}
      ],
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      // 输入建议弹框
      popupTop: '',
      popupLeft: '',
      popupShow: false,
      searchName: '',
      //主表格当前行下标
      mainTableIndex: null,
      hoverRow: null,
      goodsDialogShow: false,
      // 合计金额
      sumAmount: null,
      // 优惠后金额
      discountedAmount: null,
      preferentialRate: null,
      preferentialAmount: null,
      // 当前点击行数据
      nowRowData: {},
    };
  },
  components: {
    goodsChange,
    priceChange
  },
  props: {
    supplierList: {
      type: Array
    },
    // 1新增 2编辑 3详情
    type: {
      type: Number
    },
    formData: {
      type: Object
    },
    changeGoods: () => { },
    // 区分采购单/退货单
    modelType: {
      type: String
    },
  },
  watch: {
    formData: {
      handler(newVal, oldVal) {

        let goodsList = [];
        if (this.$props.modelType == '退货单') {
          goodsList = newVal.purchaseChildReturnOrderList
        } else {
          goodsList = newVal.purchaseChildOrderList
        }
        if (goodsList && goodsList.length > 0) {
          goodsList.forEach((item) => {
            item.name = item.goodsName
          })
          this.tableData = goodsList
          let oldGoodsList = oldVal ? oldVal.purchaseChildOrderList : [];
          if (this.mainTableIndex != null && oldGoodsList.length > 0 && goodsList[this.mainTableIndex].name != oldGoodsList[this.mainTableIndex].name) {
            this.mainTableIndex = null
          }
          this.discountedAmount = newVal.discountedAmount
          this.preferentialRate = newVal.preferentialRate
          this.preferentialAmount = newVal.preferentialAmount
        } else {
          this.tableData = [
            { purchaseChildOrderList: [] }, { purchaseChildOrderList: [] }, { purchaseChildOrderList: [] }, { purchaseChildOrderList: [] }
          ]
        }
        this.getOperationRecords()
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
  },

  methods: {
    //获取操作历史
    getOperationRecords(flag) {

      console.log(this.formData, "newVal")
      let _this = this;
      if (flag == 1) {
        if (_this.operationRecords.length == 0) {
          _this.$message({
            message: "暂无数据",
            type: "warning",
          });
        }
      }
      this.$axios
        .get(_this.$axios.defaults.basePath + "/operationRecords/getOperationRecords", {
          params: {
            id: this.formData.id
          }
        })
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.operationRecords = res.data.data;
            if (_this.operationRecords.length == 0) {
              _this.popoverDisabled = true
              // _this.$message({
              //   message: "暂无数据",
              //   type: "warning",
              // });
            } else {
              _this.popoverDisabled = false
            }

          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //单价下拉框 返回事件
    priceChangeRow(price) {
      this.$set(this.tableData[this.priceChangeData.index], "purchasePrice", price)
      this.priceChangeData.show = false
    },
    //单价输入框 失去焦点事件
    priceInputBlur() {
      setTimeout(() => {
        this.priceChangeData.show = false
      }, 500)
    },
    //单价输入框 获取焦点事件
    priceInputFocus(e, scope) {
      if (!scope.row.goodsId) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return
      }
      this.priceChangeData.index = scope.$index
      this.priceChangeData.goodsId = scope.row.goodsId
      this.priceChangeData.warehouseId = scope.row.warehouseId || this.formData.warehouseId
      let winH = window.innerHeight || document.documentElement.clientHeight;
      let inputData = this.$refs['priceInput' + scope.$index].getBoundingClientRect();
      this.priceChangeData.popupLeft = inputData.left + 'px'
      if (winH > inputData.top + 300) {
        this.priceChangeData.popupTop = inputData.top + inputData.height + 'px'
      } else {
        this.priceChangeData.popupTop = inputData.top - 260 + 'px'
      }
      this.$nextTick(() => {
        this.priceChangeData.show = true
      })
    },
    //添加时选择供应商
    chengeSupplier(e, row) {
      console.log(row)
      if (!row.goodsId) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        row.distributorId = ''
        row.distributorName = ''
        return;
      }
      for (let i in this.supplierList) {
        if (this.supplierList[i].id == e) {
          row.distributorName = this.supplierList[i].name;
        }
      }
    },

    //添加采购单弹框  添加一条商品事件
    // addGoods(index) {
    //   console.log(index)
    //   this.mainIndex = index;
    //   this.goodsDialogShow = true;
    // },

    // 获取主表格行下标
    mainTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    // 主表格点击行事件
    mainTableRowClick(row, column, event) {
      if (this.mainTableIndex != row.index) {
        this.popupShow = false
      }
      this.mainTableIndex = row.index
      this.nowRowData = row
      // this.$nextTick(()=>{
      //   this.$refs.goodsChange.goodsName = row.goodsName;
      // })
    },
    rowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },

    // 主表格单元格hover事件--显示加减按钮
    mainTableCellHover(row, column, cell, event) {
      this.hoverRow = row.index
    },
    mainTableCellLeave() {
      this.hoverRow = null
    },
    addRowClick() {
      this.tableData.push({})
    },
    delRowClick(index) {
      this.tableData.splice(index, 1)
    },

    // 表格行内数据计算
    countEvent(e, row, keyName) {

      if (Number(e) < 0) {
        this.$message({
          message: '请输入大于0的数',
          type: 'warning'
        })
        row[keyName] = 0
        return
      }
      // if(this.$props.modelType == '退货单' && keyName == 'purchaseNum'){
      //   if(Number(e) > row.num){
      //     this.$message({
      //       message: '退货数量不能大于库存数',
      //       type: 'warning'
      //     })
      //     row[keyName] = 0
      //     return
      //   }
      // }

      let num = Number(row.purchaseNum) || ''
      let price = Number(row.purchasePrice) || ''
      let total = Number(row.totalPrice) || ''

      if (num > 0 && keyName == 'price') {
        total = Number(math.multiply(num, e)).toFixed(2)
      }
      if (num > 0 && keyName == 'totalPrice') {
        price = Number(math.divide(e, num)).toFixed(2)
        console.log(price)
      }
      // console.log((math.round(math.bignumber(e), math.bignumber(num))))
      // let discountRate = ''
      // let deduction = ''
      // if (Number(row.totalPrice)>0 && keyName == 'discountRate'){
      //   discountRate = Number(e)
      //   deduction = (price * (discountRate / 100) * num).toFixed(2)
      // }
      // if (Number(row.totalPrice) > 0 && keyName == 'deduction') {
      //   deduction = Number(e)
      //   discountRate = (deduction / num / price * 100).toFixed(2)
      // }
      // let total = (num * price - deduction).toFixed(2)
      // this.$set(this.tableData[row.index], 'discountRate', discountRate)
      // this.$set(this.tableData[row.index], 'deduction', deduction)
      this.$set(this.tableData[row.index], 'purchasePrice', price)
      this.$set(this.tableData[row.index], 'totalPrice', total)

      let totalMoney = 0
      this.tableData.forEach(item => {
        if (item.totalPrice) {
          totalMoney += Number(item.totalPrice)
        }
      })
      this.discountedAmount = Number(totalMoney)
      this.youhuiCount(this.discountedAmount, 'discountedAmount')
      // 回传采购总价
      this.$emit('changeGoods', totalMoney, 'sumAmount')
    },
    //优惠金额计算
    youhuiCount(e, keyName) {
      if (Number(e) < 0) {
        this.$message({
          message: '请输入大于0的数',
          type: 'warning'
        })
        return
      }
      this.discountedAmount = Number(this.sumAmount)
      if (keyName == 'preferentialRate' && this.discountedAmount > 0) {
        this.preferentialRate = Number(e).toFixed(2)
        this.preferentialAmount = (this.discountedAmount * (Number(e) / 100)).toFixed(2)
      } else if (keyName == 'preferentialAmount' && this.discountedAmount > 0) {
        this.preferentialAmount = Number(e).toFixed(2)
        this.preferentialRate = (Number(e) / this.discountedAmount * 100).toFixed(2)
      }
      if (keyName == 'discountedAmount') {
        this.discountedAmount = Number(e).toFixed(2)
      } else {
        this.discountedAmount = (this.discountedAmount - Number(this.preferentialAmount)).toFixed(2)
      }

      let json = {
        preferentialRate: Number(this.preferentialRate),
        preferentialAmount: Number(this.preferentialAmount),
        discountedAmount: Number(this.discountedAmount),
      }
      // 回传表格数据给父组件
      this.$emit('changeGoods', json, 'youhui')
    },
    // 表格合计事件
    getSummaries(params) {
      const { columns, data } = params;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          if (index == 6 || index == 10 || index == 11) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = sums[index].toFixed(2);
            this.sumAmount = index == 11 ? Number(sums[index]).toFixed(2) : ''
          }

        } else {
          sums[index] = '';
        }
      });

      return sums;
    },

    // 子组件传递数据
    goodsDataEvent(goods) {
      let _this = this
      if (!goods) { return }
      goods.forEach((item, i) => {
        item.purchasePrice = item.price
        item.goodsId = item.id
        if (i == 0) {
          _this.$set(_this.tableData, _this.mainTableIndex, item)
        } else if (i > 0) {
          _this.tableData.splice(_this.mainTableIndex + 1, 0, item)
        }
      })
      this.$emit('changeGoods', _this.tableData, '')
    },
    // 获取popup弹框返回的商品数据
    getPopupGoods(data, index) {
      data.purchasePrice = data.price
      data.goodsId = data.id
      this.mainTableIndex = index

      this.$set(this.tableData, index, data)
      console.log(data, index, this.tableData)
      this.$emit('changeGoods', this.tableData, '')
    },

  },
};
</script>

<style lang="scss">
.my_table {
  margin: 20px;
}
.t-header {
  overflow: hidden;
}
.t-header th {
  background-color: #f1f5fc !important;
}

.my-autocomplete {
  display: none;
  width: auto !important;
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
.input_popup {
  position: fixed;
  z-index: 1000;
  border: 1px solid #e5e5e5;
}
.addOrDel {
  i {
    cursor: pointer;
    font-size: 20px;
    display: inline-block;
    margin: 0 5px;
  }
}
.youHui {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
}
.youHui > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}
.youHui > div .el-input {
  width: 160px;
}
.youHui > div span {
  display: inline-block;
  min-width: 100px;
}
// .pageMain,
// .el-table,
// .el-table__body-wrapper,
// .el-table tr,
// .el-table .cell{
//   overflow: visible;
// }
.operationRecords ul{
  max-height: 400px;
  overflow-y: scroll;
}
.popoverBtn{
  cursor: pointer;
  color: #409EFF;
}
</style>
