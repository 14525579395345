<template>
  <div>
    <div class="input_popup" :style="{ 'left': priceChangeData.popupLeft,'top': priceChangeData.popupTop }" ref="popup">
      <el-table :data="popupGoods" @row-click="priceChangeRow" style="width: 400px" header-row-class-name="t-header" height="260" :row-class-name="rowClassName"  v-loading='loading'>
        <el-table-column prop="created" label="最近采购时间">
        </el-table-column>
        <el-table-column prop="price" label="最近采购价">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popupGoods: [],
      loading: false
    }
  },
  props: {
    priceChangeData: {
      type: Object
    }
  },
  mounted() {
    this.getPurchasePriceList()
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      row.rowIndex = rowIndex
    },
    priceChangeRow(row, column, event) {
      this.$emit('priceChangeRow', row.price)
    },
    getPurchasePriceList() {
      let that = this;
      this.loading = true
      let { goodsId, warehouseId } = this.priceChangeData
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseOrder/getPurchasePriceList", {
          params: {
            goodsId, warehouseId
          },
        })
        .then(function (res) {
          that.loading = false
          if (res.data.errcode == 0) {
            that.popupGoods = res.data.data;
          }
        });
    }
  }
}
</script>

<style scoped>
.input_popup {
  position: fixed;
  z-index: 10000;
  border: 1px solid #e5e5e5;
}
</style>